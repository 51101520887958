import { template as template_5aa561f6caf24db2b6a572cf1c8b9f4d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_5aa561f6caf24db2b6a572cf1c8b9f4d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
