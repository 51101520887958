import { template as template_82f6e0b3b7b64274bcd40fad03d8de91 } from "@ember/template-compiler";
const WelcomeHeader = template_82f6e0b3b7b64274bcd40fad03d8de91(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
