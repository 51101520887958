import { template as template_970da86327e4434a93292cc3ef051de9 } from "@ember/template-compiler";
const FKLabel = template_970da86327e4434a93292cc3ef051de9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
