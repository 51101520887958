import { template as template_31bb727e0a3b47f7a7b850a0e2d42aaa } from "@ember/template-compiler";
import { Input } from "@ember/component";
import concatClass from "discourse/helpers/concat-class";
import { i18n } from "discourse-i18n";
const PreferenceCheckbox = template_31bb727e0a3b47f7a7b850a0e2d42aaa(`
  <div class={{concatClass "controls" @class}} ...attributes>
    <label class="checkbox-label">
      <Input @type="checkbox" @checked={{@checked}} disabled={{@disabled}} />

      {{#if @labelCount}}
        {{i18n @labelKey count=@labelCount}}
      {{else}}
        {{i18n @labelKey}}
      {{/if}}
    </label>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PreferenceCheckbox;
