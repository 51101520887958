import { template as template_49d44b4f8dc24414b553e777122e203a } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class MessagesSecondaryNav extends Component {
    get messagesNav() {
        return document.getElementById("user-navigation-secondary__horizontal-nav");
    }
    static{
        template_49d44b4f8dc24414b553e777122e203a(`
    {{#in-element this.messagesNav}}
      {{yield}}
    {{/in-element}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
