import { template as template_e5190a9564f74e4fa254a985a5de5a33 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_e5190a9564f74e4fa254a985a5de5a33(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
