import { template as template_fcdb7832c29a4e68b40bb46cc41d4678 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_fcdb7832c29a4e68b40bb46cc41d4678(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
